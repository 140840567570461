import { useTranslation } from '@repo/translation/react-i18next';
import { saveInLocalStorage } from '@repo/shared-utils/localStorageUtil';
import { Language, LanguageCodes } from '@repo/entities/PersonEntity';
import { USER_LANGUAGE } from './translation/constants.ts';
import { updateWorkerAction } from './actions/updateWorkerAction.ts';

export const useUpdateLanguage = () => {
  const { i18n } = useTranslation();

  const updateLanguage = async (val: Language, saveToRemote: boolean) => {
    const langCode = LanguageCodes[val];
    if (!langCode) {
      return;
    }

    try {
      await i18n.changeLanguage(langCode);
      saveInLocalStorage(USER_LANGUAGE, langCode);
      if (saveToRemote) {
        await updateWorkerAction({
          languages: [val],
        });
      }
    } catch (error) {
      alert((error as Error).toString());
    }
  };

  return { updateLanguage };
};
