import { FormControl } from '@repo/mui/Form';
import { InputLabel } from '@repo/mui/InputLabel';
import React from 'react';
import { Select as MuiSelect, SelectProps } from '@repo/mui/Select';
import { MenuItem } from '@repo/mui/Menu';

type Props = {
  label: string;
  value?: string | number | undefined;
  onChange?: (value: string) => void;
  items: string[] | { value: string; label: string }[];
  hiddenLabel?: boolean;
  translationFn?: (item: string) => void;
} & Omit<SelectProps, 'onChange'>;

export const Select = (props: Props) => {
  const { label, value, onChange, items, hiddenLabel, translationFn, ...rest } = props;

  return (
    <FormControl>
      {!hiddenLabel && (
        <InputLabel id='r-select-label' sx={{ left: 12, top: 8 }}>
          {label}
        </InputLabel>
      )}
      <MuiSelect
        data-testid={`address-select-${label}`}
        fullWidth
        labelId='r-select-label'
        label={hiddenLabel ? '' : label}
        value={value}
        onChange={(e) => {
          onChange?.(String(e.target.value));
        }}
        renderValue={(value) =>
          (translationFn ? translationFn(value as string) : value) as React.ReactNode
        }
        {...rest}>
        {items.map((item) => {
          if (typeof item === 'object' && item !== null) {
            return (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          }
        })}
      </MuiSelect>
    </FormControl>
  );
};
