import MuiFormControl from '@mui/material/FormControl';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiFormGroup from '@mui/material/FormGroup';
import MuiFormLabel from '@mui/material/FormLabel';
import MuiFormHelperText from '@mui/material/FormHelperText';
import MuiRadio from '@mui/material/Radio';
import MuiRadioGroup from '@mui/material/RadioGroup';

export const FormControl = MuiFormControl;
export const FormControlLabel = MuiFormControlLabel;
export const FormGroup = MuiFormGroup;
export const FormLabel = MuiFormLabel;
export const FormHelperText = MuiFormHelperText;
export const Radio = MuiRadio;
export const RadioGroup = MuiRadioGroup;
